import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./Tokenomics.css";

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const bottomRef = useRef<HTMLHeadingElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [ca, setCA] = useState("");

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCA("0x817eC685bd3c90Cb41Efc1e2E36449eEf065866E");
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer2.observe(homeRef.current);
    }

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="Tokenomics" id={id}>
      <div
        className="Tokenomics-container opacity"
        ref={homeRef}
        style={{ width: "100%" }}
      >
        <h1 className="About-title titlemodel">Tokenomics</h1>
        <div className="grid-col-1fr">
          <div className="Tokenomics-subcontainer">
            <img src="/images/ca.png" alt="CA" />
          </div>

          <div className="flex-container flex-direction-col flex-start justify-center gap-20">
            <h1 className="Tokenomics-subtitle">CA</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobile-column"
            >
              <span
                className="Tokenomics-subcontent"
                style={{
                  fontWeight: 500,
                  margin: 0,
                  overflowWrap: "anywhere",
                }}
              >
                {ca}
              </span>
              <div style={{ display: "flex" }}>
                <button className="copy-btn" onClick={handleCopyToClipboard}>
                  <ContentCopyIcon style={{ color: "black", width: "14px" }} />
                </button>
                {showPopup && (
                  <div className="popup">
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        position: "absolute",
                      }}
                    >
                      Copied
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {window.innerWidth < 900 ? (
            <>
              <div className="Tokenomics-subcontainer">
                <img src="/images/supply.png" alt="supply" />
              </div>
              <div className="flex-container flex-direction-col flex-start justify-center gap-20">
                <h1 className="Tokenomics-subtitle">Supply</h1>
                <h1 className="Tokenomics-subcontent">10,000,000,000,000</h1>
              </div>
            </>
          ) : (
            <>
              <div className="flex-container flex-direction-col flex-start justify-center gap-20">
                <h1 className="Tokenomics-subtitle">Supply</h1>
                <h1 className="Tokenomics-subcontent">10,000,000,000,000</h1>
              </div>

              <div className="Tokenomics-subcontainer">
                <img src="/images/supply.png" alt="supply" />
              </div>
            </>
          )}

          <div className="Tokenomics-subcontainer">
            <img src="/images/safu.png" alt="safu" />
          </div>
          <div className="flex-container flex-direction-col flex-start justify-center gap-20">
            <h1 className="Tokenomics-subtitle">SAFU</h1>
            <h1 className="Tokenomics-subcontent">LP Tokens are burnt</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
