import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Community from "../../components/Community/Community";
import FAQ from "../../components/Faq/Faq";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Main.css";
import HowToBuy from "../../components/ToBuy/HowToBuy";

function Main() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="Main" id="Main">
      <Header />
      <Home id="home" />
      <About id="about" />
      <HowToBuy />
      {/* <Roadmap /> */}
      <Tokenomics id="tokenomics" />
      <Community />
      <FAQ />
      {/* <Contact /> */}
      {showScrollButton && (
        <div
          className="scroll-to-top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <KeyboardArrowUpIcon />
        </div>
      )}
    </div>
  );
}

export default Main;
