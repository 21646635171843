import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionExpandDefault() {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#c06b38" }}
          >
            What is FOXX?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            FOXX is the FOXX that will dethrone dog meme coins, and usher in a
            new era in crypto–the age of the FOX. Foxx used to rule the
            Mongolian plains, but are now retired and helping others. But, his
            plains is now open to all. Buy and staking Foxx for crazy rewards.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#C06B38" }}
          >
            When Is $FOX Launch?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            Launch will occur at the end of the final stage of the presale.
            Then, FOXX will take the crypto world by storm and knock DOGE, SHIB,
            and BONK off the top of the crypto world, and usher in the era of
            the fox! IT’S FOXX TIME!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#C06B38" }}
          >
            What Is The FOXX Staking?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            Throughout the entire presale, and beyond, buyers can earn BONUS
            tokens simply by staking their purchases in the Staking plains. The
            current reward percentage is displayed on the buy widget and in the
            FOX dashboard.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#C06B38" }}
          >
            How To Buy Foxx?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            Currently, FOXX can only be purchased on THIS PAGE. BASE, ETH, USDT,
            BNB payments are accepted. First, download a Metamask wallet (or the
            wallet of your choice). Then, you’ll need some crypto . Buy some
            crypto from your favorite exchange, and send it to your crypto
            wallet. Then, connect your wallet to the Foxx buy widget (at the top
            of this page), select the amount of $FOXX you wish to buy, and pull
            the trigger! If you’re having trouble buying, please join our
            telegram group and ask for assistance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#C06B38" }}
          >
            Where Are My FOXX Tokens?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            Once you have bought and/or staked your $ FOXX, you’ll see them
            displayed on both the buy widget, and in the Dashboard. Please make
            sure your purchase wallet is connected to the website in order to
            view your token balance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#C06B38" }}
          >
            When Is The FOXX Presale?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            The FOXX presale is happening RIGHT NOW! That means you’re very
            early. And in crypto, you want to be early! Not only can you buy at
            the lowest price, but you can become a founding member of the FOXX
            pack!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
