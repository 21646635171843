import React from "react";

export default function Widget() {
  return (
    <iframe
      title="uniswap"
      src={`https://app.uniswap.org/swap?chain=base&inputCurrency=ETH&outputCurrency=0x817eC685bd3c90Cb41Efc1e2E36449eEf065866E`}
      height="700px"
      width="400px"
      style={{
        border: "none",
        overflow: "hidden",
        borderRadius: "16px",
        boxShadow: "none", // 그림자 제거
        filter: "blur(0px)",
      }}
    />
  );
}
