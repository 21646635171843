import React, { useEffect, useRef } from "react";
import Widget from "./SwapWidget";
import "./HowToBuy.css";

declare global {
  interface Window {
    ethereum?: any;
  }
}

export default function HowToBuy() {
  const homeRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const rightRef_2 = useRef<HTMLHeadingElement>(null);
  const rightRef_3 = useRef<HTMLHeadingElement>(null);
  const rightRef_4 = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }

    if (rightRef_3.current) {
      observer2.observe(rightRef_3.current);
    }

    if (rightRef_4.current) {
      observer2.observe(rightRef_4.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
      observer3.disconnect();
    };
  }, []);

  return (
    <div className="HowToBuy" id="howtobuy">
      <div className="HowToBuy-container">
        <h1 className="About-title gradient-text titlemodel" ref={homeRef}>
          How to buy
        </h1>

        <div className="HowToBuy-small-container" ref={rightRef}>
          <img
            src="/images/metamask.svg"
            alt="Metamask-Logo-Purple"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Create Wallet</h2>
            <p className="HowToBuy-text">
              You need a Ethereum wallet to store your $Foxx. We recommend using
              Metamask.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_2}>
          <img src="/images/eth.svg" alt="eth" className="HowToBuy-img" />
          <div>
            <h2 className="HowToBuy-subtitle">Get some $ETH</h2>
            <p className="HowToBuy-text">
              You need $ETH to pay for transactions on the Ethereum blockchain.
              You can buy $ETH on Binance or Coinbase.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_3}>
          <img
            src="/images/uniswap-color.svg"
            alt="uniswap"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Go to Uniswap</h2>
            <p className="HowToBuy-text">
              Go to Uniswap and connect your wallet.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_4}>
          <img src="/images/symbol.png" alt="swaps" className="HowToBuy-img" />
          <div>
            <h2 className="HowToBuy-subtitle">Swap $ETH for $Foxx</h2>
            <p className="HowToBuy-text">
              Swap your $ETH for $Foxx on Uniswap.
            </p>
          </div>
        </div>
        <h1 style={{ textAlign: "center" }}>or</h1>
        <div className="Widget">
          <Widget />
        </div>
      </div>
    </div>
  );
}
