import React, { useEffect, useRef } from "react";
import TextAnimation from "../Home/TextAnimation";
import { HashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./About.css";

type AboutProps = {
  id: string;
};

export default function About({ id }: AboutProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const rightRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLHeadingElement>(null);
  const bottomRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef_3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }
    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }
    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }
    if (bottomRef.current) {
      observer3.observe(bottomRef.current);
    }
    if (bottomRef_2.current) {
      observer3.observe(bottomRef_2.current);
    }
    if (bottomRef_3.current) {
      observer3.observe(bottomRef_3.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="About" id={id}>
      <div className="About-container">
        <div
          className="opacity"
          ref={leftRef}
          style={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "400px", height: "auto", gap: "20px" }}>
            <h1 className="About-title titlemodel">About Foxx</h1>
            <TextAnimation
              text="Foxx is more than just a meme coin; it's a vibrant community driven by innovation and a passion for cryptocurrency. Inspired by the clever and adaptable nature of the fox, FoxxCoin aims to bring a playful yet serious approach to the crypto world. Our mission is to create a fun and engaging platform where users can explore the potential of blockchain technology while enjoying the creativity and humor that memes bring to the table."
              durationTime={2}
              textStyle={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "1.5em",
              }}
              className="About-content"
            />
            <div className="About-button-box">
              <HashLink smooth to={"#howtobuy"}>
                Get Started
              </HashLink>
              <a href="/" target="_blank" rel="noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div
          className="opacity align-center"
          ref={rightRef}
          style={{ margin: "0 2rem" }}
        >
          <img src="/images/asset-4.png" className="About-image" alt="asset" />
        </div>
      </div>
      <div
        className="opacity"
        style={{ margin: "0", gap: "2rem", height: "100vh", width: "auto" }}
        ref={bottomRef_2}
      >
        <h1 className="About-title titlemodel">About NFT</h1>
        <div>
          <div style={{ display: "flex" }} className="About-mobile">
            <div
              style={{
                width: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextAnimation
                text="Discover the unique and captivating world of Foxx NFTs, where creativity meets technology. Our exclusive collection of digital art pieces, inspired by the clever and adaptable fox, offers a unique blend of art and blockchain innovation."
                durationTime={2}
                textStyle={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
                className="About-content"
              />
              <div className="About-button-box">
                <a href="/" target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </div>
            </div>
            <div className="align-center">
              <img
                src="/images/asset-5.png"
                className="About-image"
                alt="nfts"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
