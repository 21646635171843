import React, { useEffect, useRef, useState } from "react";
import TextAnimation from "./TextAnimation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const secondHeadingRef = useRef<HTMLHeadingElement>(null);
  const [ca, setCA] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const text = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState("88px");

  const handleImageClick = () => {
    const aboutElement = document.getElementById("about");
    if (aboutElement) {
      aboutElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCA("0x817eC685bd3c90Cb41Efc1e2E36449eEf065866E");
    function handleResize() {
      if (window.innerWidth < 1100) {
        setFontSize("52px");
      } else {
        setFontSize("72px");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (secondHeadingRef.current) {
      observer2.observe(secondHeadingRef.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Home" id={id}>
      <div className="bg"></div>
      <div ref={text} className="Home-container">
        <TextAnimation
          text="Foxx"
          durationTime={1}
          textStyle={{
            fontSize: fontSize,
            textAlign: "center",
            color: "#c06b38",
          }}
        />
        <div
          ref={secondHeadingRef}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0",
          }}
        >
          <img
            src="/images/asset.png"
            alt="home"
            className="Home-image"
            onClick={handleImageClick}
          />
        </div>
        <TextAnimation
          text="Welcome to Foxx, the meme coin themed around the clever Foxx!"
          durationTime={5}
          textStyle={{
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "500",
            marginTop: "1rem",
            color: "#000",
            minHeight: "44px",
          }}
        />

        <div className="Home-iconbtns">
          <a
            href="https://app.uniswap.org/swap?chain=base&inputCurrency=ETH&outputCurrency=0x817eC685bd3c90Cb41Efc1e2E36449eEf065866E"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/uniswap.svg"
              alt="uniswap"
              width={44}
              height={44}
            />
          </a>
          <a
            href="https://t.me/FoxxFoxxOfficial"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/telegram.svg"
              width={44}
              height={44}
              alt="telegram"
            />
          </a>
          <a href="https://x.com/Foxx_Coin" target="_blank" rel="noreferrer">
            <img
              src="/images/x-logo.svg"
              alt="twitter"
              width={44}
              height={44}
            />
          </a>
        </div>
        <div className="flex-container flex-direction-col flex-start justify-center gap-20">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mobile-column"
          >
            <span
              className="Tokenomics-subcontent"
              style={{
                fontWeight: 400,
                margin: 0,
                overflowWrap: "anywhere",
                fontSize: "14px",
                whiteSpace: "pre",
              }}
            >
              {ca}
            </span>
            <div style={{ display: "flex" }}>
              <button className="copy-btn" onClick={handleCopyToClipboard}>
                <ContentCopyIcon style={{ color: "black", width: "14px" }} />
              </button>
              {showPopup && (
                <div className="popup">
                  <p
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      position: "absolute",
                    }}
                  >
                    Copied
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
